import { Button, Tooltip } from '@dtsl/react';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useTranslation } from 'react-i18next';

export default function EndCallButton(props: { className?: string }) {
  const { room } = useVideoContext();
  const { t } = useTranslation();

  return (
    <Tooltip text={t('ROOM.LEAVE_TOOLTIP')} placement="top">
      <Button
        className={props.className}
        variant="destructive"
        label={t('ROOM.LEAVE')}
        onClick={() => room!.disconnect()}
        data-cy-disconnect
      ></Button>
    </Tooltip>
  );
}
