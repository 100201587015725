import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@dtsl/react';
import DeviceSelectionDialog from '../../../DeviceSelectionDialog/DeviceSelectionDialog';
import SettingsIcon from '../../../../icons/SettingsIcon';

export default function SettingsMenu({ className }: { className?: string }) {
  const { t } = useTranslation();
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Tooltip text={t('ROOM.OPTIONS')} placement="top">
        <Button
          ref={anchorRef}
          onClick={() => setDeviceSettingsOpen(true)}
          startIcon={<SettingsIcon />}
          className={className}
        ></Button>
      </Tooltip>
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
        }}
      />
    </>
  );
}
