import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import ScreenShareIcon from '../../../icons/ScreenShareIcon';
import { Tooltip } from '@dtsl/react';

import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  button: {
    '&[disabled]': {
      color: '#bbb',
      '& svg *': {
        fill: '#bbb',
      },
    },
  },
});

export default function ToggleScreenShareButton(props: { disabled?: boolean; className?: string; iconFill?: string }) {
  const classes = useStyles();
  const screenShareParticipant = useScreenShareParticipant();
  const { toggleScreenShare } = useVideoContext();
  const disableScreenShareButton = Boolean(screenShareParticipant);
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;
  const { t } = useTranslation();
  const SCREEN_SHARE_TEXT = t('ROOM.SCREEN_SHARE_TEXT');
  const SCREEN_SHARE_TEXT_TOOLTIP = t('ROOM.SCREEN_SHARE_TEXT_TOOLTIP');
  const SHARE_IN_PROGRESS_TEXT = t('ROOM.SHARE_IN_PROGRESS_TEXT');
  const SHARE_NOT_SUPPORTED_TEXT = t('ROOM.SHARE_NOT_SUPPORTED_TEXT');

  let tooltipMessage = SCREEN_SHARE_TEXT_TOOLTIP;

  if (disableScreenShareButton) {
    tooltipMessage = SHARE_IN_PROGRESS_TEXT;
  }

  if (!isScreenShareSupported) {
    tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
  }

  return (
    <Tooltip text={tooltipMessage} placement="top">
      <span style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}>
        {/* The span element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}
        <Button
          className={clsx(classes.button, props.className)}
          onClick={toggleScreenShare}
          disabled={isDisabled}
          startIcon={<ScreenShareIcon fill={props.iconFill} />}
          data-cy-share-screen
        >
          {SCREEN_SHARE_TEXT}
        </Button>
      </span>
    </Tooltip>
  );
}
